@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100..700&display=swap");

:root {
  --font: "Roboto Mono", monospace;
  --font_size_1: 1rem;
  --font_size_1_2: 1.2rem;

  --font_size_2: 1.5rem;
  --font_size_2_2: 1.7rem;
  --font_size_3: 2rem;
  --font_size_3_2: 2.5rem;
  --font_size_4: 4rem;

  --font_weight_1: 700;
  --font_weight_2: 500;
  --font_weight_3: 400;

  --inactive_color: #808080;
  --black_color: #000000;
  --red_color: #fc4710;

  --border-radius: 10px;
}

html,
body,
.App,
#root {
  font-family: var(--font);
  background-color: #000000;
  min-height: 100% !important;
  height: 100%;
  overflow-x: hidden;
}

html * {
  transition: 0.3s;
}

a {
  user-select: none;
  text-decoration: none;
  color: #ffffff;
}

::-webkit-scrollbar {
  width: 3px;
}

/* Стилизация скролл-полосы */
::-webkit-scrollbar-thumb {
  background-color: #808080;
}

/* Стилизация фона полосы прокрутки */
::-webkit-scrollbar-track {
  background-color: #000000;
}

.cards {
  margin-top: 100px;
}

/* .row {
  margin: 0;
} */

/* .col-md-6 {
  padding: 0;
} */

/* .container {
  padding: 0;
} */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
